<template>
  <header
    class="sn-view__header"
    :class="{
      'sn-mobile-menu--open': show,
    }"
  >
    <div class="sn-view__header-container-content">
      <router-link
        to="/"
        class="sn-view__header-logotype"
      >
        <img src="../../assets/images/smartnation_logotype.svg" alt="Smartnation">
      </router-link>
      <div class="sn-view__header-menu">
        <router-link
          to="/#our_products"
          class="sn-view__header-menu-item sn-upper"
        >
          {{ trans('label.products') }}
        </router-link>
        <router-link
          to="/#about_project"
          class="sn-view__header-menu-item sn-upper"
        >
          {{ trans('label.about_project') }}
        </router-link>
        <router-link
          :to="{
            name: 'VContacts',
          }"
          class="sn-view__header-menu-item sn-upper"
        >
          {{ trans('label.contacts') }}
        </router-link>
      </div>
      <div class="sn-view__header-menu" style="margin-left: auto;">
        <span
          class="sn-view__header-menu-item"
          :class="{
            'is-active': interfaceLang === 'kz',
          }"
          @click="$store.commit('setLocale', 'kz')"
        >{{ trans('label.language.kz') }}</span>
        <span
          class="sn-view__header-menu-item"
          :class="{
            'is-active': interfaceLang === 'ru',
          }"
          @click="$store.commit('setLocale', 'ru')"
        >{{ trans('label.language.ru') }}</span>
      </div>
      <div
        class="sn-mobile-menu__toggle"
        @click="handleMobileMenuToggle"
      />
    </div>
    <div class="sn-mobile-menu">
      <div class="sn-mobile-menu__lang-wrapper">
        <span
          class="sn-mobile-menu__lang"
          :class="{
            'is-active': interfaceLang === 'ru',
          }"
          @click="$store.commit('setLocale', 'ru')"
        >{{ trans('label.language.ru') }}</span>
        <span
          class="sn-mobile-menu__lang"
          :class="{
            'is-active': interfaceLang === 'kz',
          }"
          @click="$store.commit('setLocale', 'kz')"
        >{{ trans('label.language.kz') }}</span>
      </div>
      <ul class="sn-mobile-menu__menu-wrapper">
        <li
          class="sn-mobile-menu__menu-item sn-mobile-menu__menu-item--dropmenu"
        >
          <span>{{ trans('label.products') }}</span>
          <ul class="sn-mobile-menu__submenu-wrapper">
            <li
              v-for="(item, itemIdx) in Object.keys(products)"
              :key="itemIdx"
              class="sn-mobile-menu__submenu-item"
              @click="handleMobileMenuToggle"
            >
              <router-link
                :to="{
                  name: 'VProductPage',
                  params: {
                    name: item,
                  }
                }"
              >{{ products[item] }}</router-link>
            </li>
          </ul>
        </li>
        <li
          class="sn-mobile-menu__menu-item"
          @click="handleMobileMenuToggle"
        >
          <a href="/#about_project">{{ trans('label.about_project') }}</a>
        </li>
        <li
          class="sn-mobile-menu__menu-item"
          @click="handleMobileMenuToggle"
        >
          <router-link
            :to="{
              name: 'VContacts',
            }"
          >{{ trans('label.contacts') }}</router-link>
        </li>
      </ul>
      <div class="sn-mobile-menu__footer">
        <div class="sn-mobile-menu__phone">
          <span class="sn-mobile-menu__phone--value">{{ trans('label.phone') }}</span>
          <span
            class="sn-mobile-menu__phone--label"
            v-html="trans('label.work_schedule')"
          />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
/* eslint-disable no-undef */
import { mapGetters } from 'vuex';

export default {
  name: 'VHeader',

  data() {
    return {
      show: false,
    };
  },

  mounted() {
    $('.sn-mobile-menu__menu-item--dropmenu > span').on('click', this.handleMobileMenuDropmenu);
  },

  beforeDestroy() {

  },

  computed: {
    ...mapGetters([
      'interfaceLang',
    ]),

    products() {
      return this.$store.state.translator[this.interfaceLang].products;
    },
  },

  methods: {
    handleMobileMenuToggle() {
      this.show = !this.show;
    },

    handleMobileMenuDropmenu(e) {
      if ($(e.target).closest('.sn-mobile-menu__menu-item--dropmenu').hasClass('sn-mobile-menu__menu-item--open')) {
        $(e.target).closest('.sn-mobile-menu__menu-item--dropmenu').children('.sn-mobile-menu__submenu-wrapper').slideUp();
        $(e.target).closest('.sn-mobile-menu__menu-item--dropmenu').removeClass('sn-mobile-menu__menu-item--open');
      } else {
        $(e.target).closest('.sn-mobile-menu__menu-item--dropmenu').children('.sn-mobile-menu__submenu-wrapper').slideDown();
        $(e.target).closest('.sn-mobile-menu__menu-item--dropmenu').addClass('sn-mobile-menu__menu-item--open');
      }
    },
  },
};
</script>
