export default {
  props: {
    type: String,
    tag: String,
    to: [String, Object],
    loading: Boolean,
    label: String,
    color: String,
    attachmentLabel: String,
    size: String,
    outline: Boolean,
    disable: Boolean,
    progress: Number,
    noWrap: Boolean,
    squire: Boolean,
  },
  computed: {
    isDisabled() {
      return this.disable === true || this.loading === true;
    },
    classes() {
      return `${this.isDisabled ? 'sn-btn--disable' : ''
      }${this.color ? ` sn-btn--thm-${this.color}` : ''
      }${this.loading ? ' sn-loading' : ''
      }${this.outline ? ' sn-btn--outline' : ''
      }${this.size ? ` sn-btn--size-${this.size}` : ''
      }${this.attachmentLabel ? ' sn-btn--attachment' : ''
      }${this.progress !== undefined ? ' sn-btn--progress' : ''
      }${this.squire ? ' sn-btn--squire' : ''}`;
    },
  },
  methods: {
    click(e) {
      if (this.tag !== 'a' && typeof this.to === 'string') {
        e.preventDefault();
        this.$router.push(this.to);
      } else if (this.tag !== 'a' && typeof this.to === 'object') {
        e.preventDefault();
        this.$router.push(this.to);
      } else if (this.tag === 'a' && this.disable) {
        e.preventDefault();
      } else if (this.tag !== 'a' && !this.to) {
        this.$emit('click', e);
      }
    },
  },
  render(h) {
    const attrs = {
      type: this.type,
      disabled: this.isDisabled,
    };

    if (this.tag === 'a' && typeof this.to === 'string') {
      attrs.href = this.to;
    }

    return h(
      this.tag ? this.tag : 'button',
      {
        staticClass: 'sn-btn',
        class: this.classes,
        attrs,
        on: {
          ...this.$listeners,
          click: this.click,
        },
      },
      [
        this.progress !== undefined && h('div', {
          staticClass: 'sn-btn__progress',
          style: {
            width: `${this.progress}%`,
          },
        }),
        this.attachmentLabel && h('div', {
          staticClass: 'sn-btn__attachment-label',
        }, this.attachmentLabel),
        this.label
          ? h('span', {
            class: {
              'sn-ellipsis': this.noWrap,
            },
          }, this.label)
          : this.$slots.default,
      ],
    );
  },
};
