/* eslint-disable prefer-rest-params */

export default function (fn, wait = 250, immediate) {
  let timeout;

  function debounced(/* ...args */) {
    const args = arguments;

    const later = () => {
      timeout = null;
      if (!immediate) {
        fn.apply(this, args);
      }
    };

    clearTimeout(timeout);
    if (immediate && !timeout) {
      fn.apply(this, args);
    }
    timeout = setTimeout(later, wait);
  }

  debounced.cancel = () => {
    clearTimeout(timeout);
  };

  return debounced;
}
