<template>
  <section
    id="our_products"
    class="sn-container-content-wrapper sn-view__our-products"
  >
    <div class="sn-container-content">
      <div class="sn-title sn-title--double sn-upper">
        <span>{{ trans('label.our_products') }}</span>
      </div>
      <div class="sn-our-products__wrapper">
        <div
          v-for="(item, itemIdx) in items"
          :key="itemIdx"
          class="sn-our-products"
        >
          <div
            class="sn-our-products__pic"
            :style="`background-image: url('/images/products/${item.name}_pic.svg');`"
          />
          <div class="sn-our-products__content">
            <div class="sn-our-products__logo">
              <img
                :src="`/images/products/${item.name}_logotype.svg`"
                :alt="item.name"
              >
            </div>
            <div class="sn-our-products__desc">
              {{ item.desc }}
            </div>
            <a
              v-if="item.name === 'esep'"
              class="sn-our-products__button"
              href="https://esep.snation.kz"
            >
              <span>{{ trans('action.more') }}</span>
            </a>
            <router-link
              v-else
              tag="div"
              :to="{
                name: 'VProductPage',
                params: {
                  name: item.name,
                },
              }"
              class="sn-our-products__button"
            >
              <span>{{ trans('action.more') }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'VOurProducts',

  computed: {
    items() {
      return this.$store.state.translator[this.$store.getters.interfaceLang].text.our_products;
    },
  },
};
</script>
