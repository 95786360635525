<template>
  <footer class="sn-view__footer">
    <div class="sn-view__footer-container-content">
      <div class="sn-footer-content__info">
        <router-link to="/">
        <img src="../../assets/images/footer_logotype.svg" alt="Smartnation">
        </router-link>
        <p>{{ trans('text.footer.incraft') }}</p>
        <p>{{ trans('text.footer.phone') }}</p>
        <p>{{ trans('text.footer.phone_number') }}</p>
      </div>
      <div class="sn-footer-content__menu-wrapper">
        <span>{{ trans('text.footer.links') }}</span>
        <ul class="sn-footer-content__menu">
          <li>
            <router-link to="/#our_products">{{ trans('label.our_products') }}</router-link>
          </li>
          <li>
            <a href="/#about_project">{{ trans('label.about_project') }}</a>
          </li>
          <li>
            <router-link
              :to="{
                name: 'VContacts',
              }"
            >{{ trans('label.contacts') }}</router-link>
          </li>
          <li>
            <router-link to="/#integrations">{{ trans('label.integrations') }}</router-link>
          </li>
          <li>
            <router-link to="/#statistic">{{ trans('label.project_statistic') }}</router-link>
          </li>
        </ul>
      </div>
      <div class="sn-footer-content__our-projects">
        <a :href="`https://${item}.snation.kz`" target="_blank"
           v-for="(item, itemIdx) in products"
           :key="itemIdx"
        >
        <img

          class="sn-footer-content__our-projects--balabaqsha"
          :src="`/images/footer_logotypes/${item}_footer_logotype.svg`"
          :alt="item"></a>
      </div>
    </div>
    <div class="sn-view__footer-container-social">
      <ul class="sn-footer-content__social">
        <li
          v-for="(item, itemIdx) in social"
          :key="itemIdx"
          :class="`sn-footer-content__social--${item.id}`"
        >
          <a
            :href="item.href"
            target="_blank"
          >{{ item.name }}</a>
        </li>
      </ul>
    </div>
    <div class="sn-view__footer-copyright">
      <span>{{ `© ${year} ${trans('text.footer.copyright')}` }}</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'VFooter',

  computed: {
    year() {
      return (new Date()).getFullYear();
    },
    products() {
      return this.$store.state.translator[this.$store.getters.interfaceLang]
        .text.footer.products;
    },

    social() {
      return this.$store.state.translator[this.$store.getters.interfaceLang].text.footer.social;
    },
  },
};
</script>
