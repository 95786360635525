import debounce from '@/helpers/debounce';

export default {
  install(Vue) {
    const sizes = {
      sm: 576,
      md: 768,
      lg: 1023,
      xl: 1600,
    };
    const results = {
      sizes,
      width: window.innerWidth,
      height: window.innerHeight,
      outerWidth: window.outerWidth,
      outerHeight: window.outerHeight,
      lt: {
        sm: window.innerWidth < sizes.sm,
        md: window.innerWidth < sizes.md,
        lg: window.innerWidth < sizes.lg,
        xl: window.innerWidth < sizes.xl,
      },
      gt: {
        xs: window.innerWidth >= sizes.sm,
        sm: window.innerWidth >= sizes.md,
        md: window.innerWidth >= sizes.lg,
        lg: window.innerWidth >= sizes.xl,
      },
      xs: true,
      sm: false,
      md: false,
      lg: false,
      xl: false,
    };

    const calc = (e) => {
      const curWidth = e.target.innerWidth;
      const curHeight = e.target.innerHeight;

      results.gt = {
        xs: curWidth >= results.sizes.sm,
        sm: curWidth >= results.sizes.md,
        md: curWidth >= results.sizes.lg,
        lg: curWidth >= results.sizes.xl,
      };

      results.lt = {
        sm: curWidth < results.sizes.sm,
        md: curWidth < results.sizes.md,
        lg: curWidth < results.sizes.lg,
        xl: curWidth < results.sizes.xl,
      };

      results.width = curWidth;
      results.height = curHeight;
      results.outerWidth = e.target.outerWidth;
      results.outerHeight = e.target.outerHeight;
      results.xs = results.lt.sm;
      results.sm = results.gt.xs && results.lt.md;
      results.md = results.gt.sm && results.lt.lg;
      results.lg = results.gt.md && results.lt.xl;
      results.xl = results.gt.lg;
    };

    window.addEventListener('resize', debounce(calc));

    Vue.util.defineReactive(Vue.prototype, '$screen', results);
  },
};
