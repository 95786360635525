<template>
  <div>
    <v-head-block/>
    <v-our-products/>
    <v-integrations/>
    <v-about-us/>
    <v-statistics/>
    <v-social/>
  </div>
</template>

<script>
import VHeadBlock from '@/components/VHeadBlock';
import VOurProducts from '@/components/VOurProducts';
import VIntegrations from '@/components/VIntegrations';
import VAboutUs from '@/components/VAboutUs';
import VStatistics from '@/components/VStatistics';
import VSocial from '@/components/VSocial';

export default {
  name: 'VHome',

  components: {
    VHeadBlock,
    VOurProducts,
    VIntegrations,
    VAboutUs,
    VStatistics,
    VSocial,
  },
};
</script>
