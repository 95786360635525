import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'VNotify',

  data() {
    return {
      items: [],
      maxVisible: 3,
    };
  },

  mounted() {
    this.$eventHub.$on('v-notify-top-right', this.addNotify);
  },

  methods: {
    addNotify(item) {
      if (this.items.length >= this.maxVisible) {
        this.removeNotify(0)();
      }

      const key = uuidv4();

      if (item.duration) {
        setTimeout(() => {
          this.removeNotifyByKey(key);
        }, item.duration);
      }

      this.items.push({
        ...item,
        key,
      });
    },

    removeNotify(index) {
      return () => {
        this.items.splice(index, 1);
      };
    },

    removeNotifyByKey(key) {
      if (key) {
        this.items = this.items.filter((item) => key !== item.key);
      }
    },

    createNotify(h, item, itemIdx) {
      return h('div', {
        key: item.text + item.key,
        staticClass: 'ol-notify',
        class: {
          'ol-notify--success': item.type === 'success',
          'ol-notify--warning': item.type === 'warning',
          'ol-notify--error': item.type === 'error',
          'ol-notify--info': item.type === 'info',
        },
      }, [
        h('button', {
          staticClass: 'ol-notify__close',
          on: {
            click: this.removeNotify(itemIdx),
          },
        }),
        h('div', { staticClass: 'ol-notify__title' }, item.title),
        h('div', {
          staticClass: 'ol-notify__text',
          domProps: {
            innerHTML: item.text,
          },
        }),
      ]);
    },
  },

  render(h) {
    return h('div', [
      h('transition-group', {
        staticClass: 'ol-notify__wrapper ol-notify__wrapper--pos-top-right',
        props: {
          name: 'ol-transition--flip-right',
          tag: 'div',
        },
      }, this.items.map((item, itemIdx) => {
        if (item.position === 'top-right') {
          return this.createNotify(h, item, itemIdx);
        }

        return undefined;
      })),
    ]);
  },
};
