import Vue from 'vue';
import VueRouter from 'vue-router';
import VHome from '../views/VHome';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'VHome',
    component: VHome,
  },
  {
    path: '/contacts',
    name: 'VContacts',
    component: () => import('../views/VContacts'),
  },
  {
    path: '/products/:name',
    name: 'VProductPage',
    props: true,
    component: () => import('../views/VProductPage'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash };
    }

    return { x: 0, y: 0 };
  },
});

export default router;
