var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"sn-view__header",class:{
    'sn-mobile-menu--open': _vm.show,
  }},[_c('div',{staticClass:"sn-view__header-container-content"},[_c('router-link',{staticClass:"sn-view__header-logotype",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../../assets/images/smartnation_logotype.svg"),"alt":"Smartnation"}})]),_c('div',{staticClass:"sn-view__header-menu"},[_c('router-link',{staticClass:"sn-view__header-menu-item sn-upper",attrs:{"to":"/#our_products"}},[_vm._v(" "+_vm._s(_vm.trans('label.products'))+" ")]),_c('router-link',{staticClass:"sn-view__header-menu-item sn-upper",attrs:{"to":"/#about_project"}},[_vm._v(" "+_vm._s(_vm.trans('label.about_project'))+" ")]),_c('router-link',{staticClass:"sn-view__header-menu-item sn-upper",attrs:{"to":{
          name: 'VContacts',
        }}},[_vm._v(" "+_vm._s(_vm.trans('label.contacts'))+" ")])],1),_c('div',{staticClass:"sn-view__header-menu",staticStyle:{"margin-left":"auto"}},[_c('span',{staticClass:"sn-view__header-menu-item",class:{
          'is-active': _vm.interfaceLang === 'kz',
        },on:{"click":function($event){return _vm.$store.commit('setLocale', 'kz')}}},[_vm._v(_vm._s(_vm.trans('label.language.kz')))]),_c('span',{staticClass:"sn-view__header-menu-item",class:{
          'is-active': _vm.interfaceLang === 'ru',
        },on:{"click":function($event){return _vm.$store.commit('setLocale', 'ru')}}},[_vm._v(_vm._s(_vm.trans('label.language.ru')))])]),_c('div',{staticClass:"sn-mobile-menu__toggle",on:{"click":_vm.handleMobileMenuToggle}})],1),_c('div',{staticClass:"sn-mobile-menu"},[_c('div',{staticClass:"sn-mobile-menu__lang-wrapper"},[_c('span',{staticClass:"sn-mobile-menu__lang",class:{
          'is-active': _vm.interfaceLang === 'ru',
        },on:{"click":function($event){return _vm.$store.commit('setLocale', 'ru')}}},[_vm._v(_vm._s(_vm.trans('label.language.ru')))]),_c('span',{staticClass:"sn-mobile-menu__lang",class:{
          'is-active': _vm.interfaceLang === 'kz',
        },on:{"click":function($event){return _vm.$store.commit('setLocale', 'kz')}}},[_vm._v(_vm._s(_vm.trans('label.language.kz')))])]),_c('ul',{staticClass:"sn-mobile-menu__menu-wrapper"},[_c('li',{staticClass:"sn-mobile-menu__menu-item sn-mobile-menu__menu-item--dropmenu"},[_c('span',[_vm._v(_vm._s(_vm.trans('label.products')))]),_c('ul',{staticClass:"sn-mobile-menu__submenu-wrapper"},_vm._l((Object.keys(_vm.products)),function(item,itemIdx){return _c('li',{key:itemIdx,staticClass:"sn-mobile-menu__submenu-item",on:{"click":_vm.handleMobileMenuToggle}},[_c('router-link',{attrs:{"to":{
                name: 'VProductPage',
                params: {
                  name: item,
                }
              }}},[_vm._v(_vm._s(_vm.products[item]))])],1)}),0)]),_c('li',{staticClass:"sn-mobile-menu__menu-item",on:{"click":_vm.handleMobileMenuToggle}},[_c('a',{attrs:{"href":"/#about_project"}},[_vm._v(_vm._s(_vm.trans('label.about_project')))])]),_c('li',{staticClass:"sn-mobile-menu__menu-item",on:{"click":_vm.handleMobileMenuToggle}},[_c('router-link',{attrs:{"to":{
            name: 'VContacts',
          }}},[_vm._v(_vm._s(_vm.trans('label.contacts')))])],1)]),_c('div',{staticClass:"sn-mobile-menu__footer"},[_c('div',{staticClass:"sn-mobile-menu__phone"},[_c('span',{staticClass:"sn-mobile-menu__phone--value"},[_vm._v(_vm._s(_vm.trans('label.phone')))]),_c('span',{staticClass:"sn-mobile-menu__phone--label",domProps:{"innerHTML":_vm._s(_vm.trans('label.work_schedule'))}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }