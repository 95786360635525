<template>
  <div
    class="sn-view__page"
  >
    <v-header/>
    <transition name="ol-transition--fade">
      <router-view/>
    </transition>
    <v-footer/>
    <v-notify></v-notify>
  </div>
</template>

<script>
import '@/assets/styles/style.scss';
import VNotify from '@/components/VNotify';
import VHeader from '@/components/VHeader';
import VFooter from '@/components/VFooter';

export default {
  name: 'App',

  components: {
    VNotify,
    VHeader,
    VFooter,
  },
};
</script>
