<template>
  <section class="sn-container-content-wrapper sn-view__social">
    <div class="sn-container-content">
      <div class="sn-title sn-title--double sn-upper">
        <span>{{ trans('label.we_in_social') }}</span>
      </div>
      <div class="sn-social__wrapper">
        <div class="sn-social__head">smartnation_kz</div>
        <div class="sn-social__body">
          <div class="sn-social__carousel">
            <v-insta
              url="https://college.snation.kz/ru/instagram/feed"
            />
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-sm-auto col-md-4">
              <v-btn
                tag="a"
                to="https://www.instagram.com/smartnation_kz/?hl=ru"
                target="_blank"
                :label="trans('action.subscribe')"
                size="b"
                color="white"
                class="sn-upper sn-w-100 sn-maxw-400"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VBtn from '@/components/VBtn';
import VInsta from '../VInsta';

export default {
  name: 'VSocial',

  components: {
    VBtn,
    VInsta,
  },
};
</script>
