<template>
  <section
    id="integrations"
    class="sn-container-content-wrapper sn-view__integrations">
    <div class="sn-container-content">
      <div class="sn-title sn-title--double sn-c-white sn-upper">
        <span>{{ trans('label.integrations') }}</span>
      </div>
      <div class="sn-integrations__wrapper">
        <a
          v-for="(item, itemIdx) in items"
          :key="itemIdx"
          :href="item.href"
          target="_blank"
          class="sn-integrations"
        >
          <div class="sn-integrations__pic">
            <img :src="`/images/integrations/${itemIdx + 1}.png`">
          </div>
          <div class="sn-integrations__label">{{ item.name }}</div>
        </a>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-sm-auto col-md-4">
          <v-btn
            :to="{
              name: 'VContacts',
            }"
            color="white"
            size="b"
            :label="trans('action.connect')"
            class="sn-upper sn-w-100 sn-maxw-400"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VBtn from '@/components/VBtn';

export default {
  name: 'VIntegrations',

  components: {
    VBtn,
  },

  computed: {
    items() {
      return this.$store.state.translator[this.$store.getters.interfaceLang].text.integrations;
    },
  },
};
</script>
