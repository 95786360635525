import action from './action.json';
import label from './label.json';
import products from './products.json';
import text from './text.json';

export default {
  action,
  label,
  products,
  text,
};
