import axios from 'axios';
// import _ from 'lodash';

export const DEFAULT_TEXT = {
  error: {
    title: 'olessons.notify.error.title',
    message: 'olessons.notify.error.message',
  },
  warning: {
    title: 'olessons.notify.warning.title',
    message: 'olessons.notify.warning.message',
  },
  success: {
    title: 'olessons.notify.success.title',
    message: 'olessons.notify.success.message',
  },
};

export const errorCodes = {
  ERR_302: 302,
  ERR_401: 401,
  ERR_403: 403,
  ERR_404: 404,
  ERR_405: 405,
  ERR_413: 413,
  ERR_418: 418,
  ERR_422: 422,
  ERR_500: 500,
  ERR_502: 502,
  ERR_MESSAGE: 666,
};

function pushNotify(context, type, message, title, duration = 7000) {
  const params = {
    type,
    title: title || context.trans(DEFAULT_TEXT[type].title),
    text: message || context.trans(DEFAULT_TEXT[type].message),
  };

  if (duration) {
    params.duration = duration;
  }

  context.$notify(params);
}

function catch302(data, external) {
  const response = {
    error: true,
    errorType: errorCodes.ERR_302,
    data,
  };

  if (response.data && response.data.redirect && !external) {
    window.location.href = response.data.redirect;
  }

  return response;
}

function catch401(data, external) {
  const response = {
    error: true,
    errorType: errorCodes.ERR_401,
    [data.message ? 'message' : 'data']: data.message || data,
  };

  if (!data.action && this && this.$notify && !external) {
    pushNotify(this, 'error', response.message);
  }

  return {
    error: true,
    errorType: errorCodes.ERR_401,
    data,
  };
}

function catch422(data, external) {
  const response = {
    error: true,
    errorType: errorCodes.ERR_422,
    data,
  };

  if (!data.errors && this && this.$notify && !external) {
    pushNotify(this, 'error', data.message);
  } else if (Array.isArray(data.errors) && this && this.$notify && !external) {
    pushNotify(this, 'error', data.errors[0]);
  } else if (this && this.addValidationError && !external) {
    Object.keys(data.errors).forEach((key) => {
      this.addValidationError(key, data.errors[key]);
    });
    // _.forEach(data.errors, (val, key) => {
    //   this.addValidationError(key, val);
    // });
  }

  console.error((data && data.response && data.response.data) || data);
  return response;
}

function catchDefault(code, data, external) {
  const response = {
    error: true,
    errorType: code,
    [data.message ? 'message' : 'data']: data.message || data,
  };

  if (!data.action && this && this.$notify && !external) {
    pushNotify(this, 'error', response.message);
  }

  console.error((data && data.response && data.response.data) || data);
  return response;
}

function catch500(code, data) {
  const response = {
    error: true,
    errorType: code,
    [data.message ? 'message' : 'data']: data.message || data,
  };

  // if (!data.action && this && this.$notify && !external) {
  //   pushNotify(this, 'error', response.message);
  // }

  console.error((data && data.response && data.response.data) || data);
  return response;
}

function catch502(code, data) {
  const response = {
    error: true,
    errorType: code,
    [data.message ? 'message' : 'data']: data.message || data,
  };

  // if (!data.action && this && this.$notify && !external) {
  //   pushNotify(this, 'error', response.message);
  // }

  console.error((data && data.response && data.response.data) || data);
  return response;
}

export default async function requestWrapper(options = {}, external = false) {
  try {
    const res = await axios(options);

    if (!external && res.data.alert && res.data.alert.message) {
      pushNotify(
        this,
        'success',
        res.data.alert.message,
        res.data.alert.title,
        7000,
      );
    }

    if (!external && res.data.warning && res.data.warning.message) {
      pushNotify(
        this,
        'warning',
        res.data.warning.message,
        res.data.warning.title,
        7000,
      );
    }

    return (res && res.data) || res;
  } catch (err) {
    if (err && err.response) {
      const {
        status,
        data,
      } = err.response;

      console.warn(err.response);

      switch (status) {
        case errorCodes.ERR_302: return catch302.call(this, data, external);
        case errorCodes.ERR_401: return catch401.call(this, data, external);
        case errorCodes.ERR_422: return catch422.call(this, data, external);
        case errorCodes.ERR_500: return catch500.call(this, data);
        case errorCodes.ERR_502: return catch502.call(this, data);
        default: return catchDefault.call(this, status, data, external);
      }
    }

    return err;
  }
}
