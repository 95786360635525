<template>
  <section class="sn-container-content-wrapper sn-view__head-block">
    <div class="sn-view__head-block-bottom"></div>
    <div class="sn-container-content desktop">
      <div class="sn-head-block__wrapper">
        <div class="sn-head-block__content">
          <div class="sn-head-block__title">
            {{ trans('text.head_block.title') }}
          </div>
          <div class="sn-head-block__desc">
            {{ trans('text.head_block.desc') }}
          </div>
          <div class="sn-head-block__desc-additional">
            {{ trans('text.head_block.desc_additional') }}
          </div>
        </div>
        <div
          class="sn-head-block__map"
        >
          <img :src="`/images/map_${$store.state.common.lang}.svg`" alt="map">
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-sm-auto col-md-4">
          <v-btn
            :to="{
              name: 'VContacts',
            }"
            color="green"
            size="b"
            :label="trans('action.connect')"
            class="sn-upper sn-w-100 sn-maxw-400"
          />
        </div>
      </div>
    </div>
    <div class="sn-container-content mobile">
      <div class="sn-head-block__wrapper">
        <div class="sn-head-block__content">
          <div class="sn-head-block__title">
            {{ trans('text.head_block.title') }}
          </div>
          <div
          class="sn-head-block__map"
        >
          <img :src="`/images/map_${$store.state.common.lang}.svg`" alt="map">
        </div>
          <div class="sn-head-block__desc">
            {{ trans('text.head_block.desc') }}
          </div>

        </div>

      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-sm-auto col-md-4">
          <v-btn
            :to="{
              name: 'VContacts',
            }"
            color="green"
            size="b"
            :label="trans('action.connect')"
            class="sn-upper sn-w-100 sn-maxw-400"
          />
        </div>
      </div>
      <div class="sn-head-block__desc-additional">
            {{ trans('text.head_block.desc_additional') }}
      </div>
    </div>
  </section>
</template>

<script>
import VBtn from '@/components/VBtn';

export default {
  name: 'VHeadBlock',
  components: {
    VBtn,
  },
};
</script>
