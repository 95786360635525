<template>
  <section
    id="statistic"
    class="sn-container-content-wrapper sn-view__statistics">
    <div class="sn-container-content">
      <div class="sn-title sn-title--double sn-upper">
        <span>{{ trans('label.our_statistic') }}</span>
      </div>
      <div class="sn-statistics__wrapper">
        <div
          v-for="(line, lineIdx) in items"
          :key="lineIdx"
          class="sn-statistics__line"
        >
          <div
            v-for="(item, itemIdx) in line"
            :key="itemIdx"
            class="sn-statistics"
          >
            <div class="sn-statistics__value">{{ item.value }}</div>
            <div class="sn-statistics__label">{{ item.label }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'VStatistics',

  computed: {
    items() {
      return this.$store.state.translator[this.$store.getters.interfaceLang].text.statistics;
    },
  },
};
</script>
