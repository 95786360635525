import Vue from 'vue';
import axios from 'axios';
import VueMask from 'v-mask';
import VueTimeago from 'vue-timeago';
import locRu from '@/components/VInsta/locales/ru';
import locKz from '@/components/VInsta/locales/kz';
import Notification from './plugins/Notification';
import Translator from './plugins/Translator';
import Screen from './plugins/Screen';
import requestWrapper from './helpers/requestWrapper';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

if (document.getElementById('v-app')) {
  if (!localStorage.getItem('lang')) {
    localStorage.setItem('lang', 'ru');
  }

  axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept-Language': localStorage.getItem('lang'),
  };

  Vue.use(Notification);
  Vue.use(VueMask);
  Vue.use(Screen);
  Vue.use(VueTimeago, {
    name: 'Timeago',
    locale: 'ru',
    locales: {
      ru: locRu,
      kz: locKz,
    },
  });
  Vue.use(Translator, {
    store,
  });
  Vue.prototype.$trans.add();
  Vue.prototype.trans = store.getters.trans;
  Vue.prototype.$http = requestWrapper;
  Vue.prototype.$locale = localStorage.getItem('lang');

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#v-app');
}
