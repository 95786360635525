import locRu from '@/locales/ru';
import locKz from '@/locales/kz';

const Translator = {
  install(Vue, options) {
    Vue.prototype.$trans = {
      add: () => {
        options.store.commit('setLang', {
          locale: 'ru',
          items: locRu,
        });
        options.store.commit('setLang', {
          locale: 'kz',
          items: locKz,
        });
        options.store.commit('setLangLoading', false);
      },
      get: (key = '', params) => options.store.getters.trans(key, params),
    };
  },
};

export default Translator;
